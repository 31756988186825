export const employersByPayrollAdminEmail = ` 
query employersByPayrollAdminEmail($email: String){
  employersByPayrollAdminEmail(
    email: $email
  ) {
    id
    name
  }
} 
`;
export const payrollGroupName = ` 
query payrollGroup($id: ID!){
  payrollGroup(
    id: $id
  ) {
    id
    name
  }
} 
`;

export const payrollGroup = ` 
query payrollGroup($id: ID!){
  payrollGroup(
    id: $id
  ) {
    id
    name
    payPeriod {
      intervalType
      startDate
      cutoffTime
      nextPayDateAt
      updatedAt
    }
    paymentSlots {
      payment {
        id
        name
        uploadedAt
        amount
        status 
      }
      payDateAt
      paymentUploadLink {
        uploadUrl
        objectKey
      }
    }
  }
} 
`;

export const newOrChangedEmployeeContributions = ` 
query newOrChangedEmployeeContributions($id: ID!){
  newOrChangedEmployeeContributions(
    payrollGroupId: $id
  ) {
    id
    updatedAt
    name
    nino
    amount
    amountType
    product
    status
    type
    employeeId
  }
} 
`;

export const newOrChangedEmployeeContributionsCount = ` 
query newOrChangedEmployeeContributions($id: ID!){
  newOrChangedEmployeeContributions(
    payrollGroupId: $id
  ) {
    id
  }
} 
`;

export const validatePayrollIntegrationCsvMapping = ` 
query validatePayrollIntegrationCsvMapping($input: ValidatePayrollIntegrationCsvMappingInput!){
  validatePayrollIntegrationCsvMapping(
    input: $input
  ) {
    success
    resultItems {
      csvItem {
        name
        nino
        regularAmount
        singleAmount
      }
      errors
    } 
  }
} 
`;
